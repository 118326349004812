import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import Accordion from "@src/components/core-accordion"

const StyledAffiliatePageSection = styled(ResponsiveSection)`
  & > h3 {
    font-weight: bold;
    & + div {
      margin-top: 1em;
    }
  }
  & > h4 {
    text-align: center;
  }
`
const StyledFaqSection = styled(ResponsiveSection)`
  & > h2 {
    text-align: left;
  }
  margin-bottom: 2.5em;
`
const StyledProgramBenefits = styled(StyledAffiliatePageSection)`
  & > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 0 0;
    & > li {
      display: grid;
      margin: 20px 0;
      & > h3 {
        grid-row: 2/3;
      }
      & > img {
        grid-row: 1/2;
        height: 100px;
        width: auto;
        margin: 0 auto 10px;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > ul {
      flex-direction: row;
      align-items: flex-start;
      margin: 20px -10px 0;
      & > li {
        flex: 1 1 100%;
        margin: 0 10px;
      }
    }
  }
`

const parseAffiliateProgramContent = content => {
  const contentSplit = content.split(/(<h(4|2)>.+?<\/h\2>)\n?/)

  const parsed = contentSplit.reduce((result, item) => {
    if (!item || item === "4" || item === "2") {
      return result
    }
    let addAfter = null
    item = item.trim()
    const inFaq =
      result.length && result[result.length - 1].title === "<h2>FAQ</h2>"
    const checkOpening = item.substr(0, 3)
    if (!inFaq && (checkOpening === "<h4" || checkOpening === "<h2")) {
      // starting a new section
      result.push({ title: item.replace("<h4>", '<h4 class="h2">') })
    } else if (result.length) {
      // handle content item
      if (inFaq) {
        // split at the first <h3
        // the page template doesn't have an <h2> starting the next section
        const itemParse = item.split(/(<h3>.+?<\/h3>)/)
        if (itemParse.length > 1) {
          item = itemParse[0]
          addAfter = { content: itemParse.slice(1).join("") }
        }
      }
      if (!result[result.length - 1].content) {
        result[result.length - 1].content = item
      } else {
        result[result.length - 1].content += item
      }
    } else {
      result.push({ content: item })
    }
    if (addAfter) {
      result.push(addAfter)
    }
    return result
  }, [])
  return parsed
}

const renderProgramBenefitsContent = ({ title, content }, key) => {
  const programBenefitsRX = new RegExp(
    /<div class="image-block">(<img .+?>)+<\/div>\s*(<h5>.+?<\/h5>\s*)(.*)/,
    "g"
  )
  const contentParsed = []
  let contentParse
  while ((contentParse = programBenefitsRX.exec(content)) !== null) {
    contentParsed.push(
      `<li>${contentParse[1]}${contentParse[2].replace(
        "<h5>",
        '<h5 class="h3">'
      )}${contentParse.slice(3).join("")}</li>`
    )
  }
  return (
    <StyledProgramBenefits
      key={key}
      html={`${title}\n<ul>${contentParsed.join("\n")}</ul>`}
    />
  )
}
const renderFaqContent = ({ title, content }, key) => {
  const contentSplit = content.split(/(<h4>.+?<\/h4>)\n?/)
  const contentParsed = contentSplit.reduce((result, item) => {
    item = item.trim()
    if (!item) {
      return result
    }
    if (item.substr(0, 3) === "<h4") {
      // starting a new section
      result.push({ title: item.replaceAll(/(<\/?h4.*?>)/g, "") })
    } else if (result.length) {
      if (!result[result.length - 1].content) {
        result[result.length - 1].content = item
      } else {
        result[result.length - 1].content += item
      }
    } else {
      result.push({ content: item })
    }
    return result
  }, [])
  return (
    <StyledFaqSection key={key}>
      <h2>{title.replaceAll(/(<\/?h2.*?>)/g, "")}</h2>
      <Accordion id="affiliate-program-faq" items={contentParsed} />
    </StyledFaqSection>
  )
}

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        content,
        images: { header: headerImage },
        seo: pageSeo,
      },
    },
  } = data
  const contentParse = parseAffiliateProgramContent(content)
  GTM.dataLayerPushPageInfo({
    template: "static-affiliate-program",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <HeaderSimple spacer="margin" image={headerImage} />
      <ResponsiveSection>
        <h1 className="fancy">{title}</h1>
      </ResponsiveSection>
      {contentParse.map((item, index) => {
        const key = `affiliate-program-section-${index}`
        if (item.title === '<h4 class="h2">Program Benefits</h4>') {
          return renderProgramBenefitsContent(item, key)
        } else if (item.title === "<h2>FAQ</h2>") {
          return renderFaqContent(item, key)
        }
        item.content = item.content.replaceAll(
          /<p><a(.*?)>(.*?)(?:\s*<i.*?><\/i>\s*)?<\/a><\/p>/g,
          '<p><a$1 class="button">$2</a></p>'
        )
        return (
          <StyledAffiliatePageSection
            key={key}
            html={`${item.title || ""}${item.content || ""}`}
          />
        )
      })}
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
